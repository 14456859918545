import bar from './bar';
import ell from './ell';
import dot from './dot';

export default {
  cases: [
    bar,
    ell,
    dot,
  ],
  name: 'OLL Edge Cases',
};
