import b from './b';
import d from './d';
import e from './e';
import f from './f';
import l from './l';
import m from './m';
import r from './r';
import s from './s';
import u from './u';

export default {
  b,
  d,
  e,
  f,
  l,
  m,
  r,
  s,
  u,
};
