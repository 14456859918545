import edgeOLL from './oll/edge';
import cornerOLL from './oll/corner';
import cornerPLL from './pll/corner';
import edgePLL from './pll/edge';

export default [
  edgeOLL,
  cornerOLL,
  cornerPLL,
  edgePLL,
];
