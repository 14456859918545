import jPerm from './j-perm';
import nPerm from './n-perm';

export default {
  cases: [
    jPerm,
    nPerm,
  ],
  name: 'PLL Corner Cases',
};
