import any from './any';
import one from './one';
import pri from './pri';
import two from './two';

export default {
  any,
  one,
  pri,
  two,
};
