import b from './b';
import d from './d';
import f from './f';
import l from './l';
import r from './r';
import u from './u';

export default {
  b,
  d,
  f,
  l,
  r,
  u,
};
