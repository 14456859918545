import oll from '../4lll/oll/corner';
import pll from '../4lll/pll/corner';
import eo from './eo';
import lr from './lr';
import ep from './ep';

export default [
  oll,
  pll,
  eo,
  lr,
  ep,
];
